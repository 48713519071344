// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cjenik-jsx": () => import("./../../../src/pages/cjenik.jsx" /* webpackChunkName: "component---src-pages-cjenik-jsx" */),
  "component---src-pages-igraonica-jsx": () => import("./../../../src/pages/igraonica.jsx" /* webpackChunkName: "component---src-pages-igraonica-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-onama-jsx": () => import("./../../../src/pages/onama.jsx" /* webpackChunkName: "component---src-pages-onama-jsx" */),
  "component---src-pages-prijevodi-jsx": () => import("./../../../src/pages/prijevodi.jsx" /* webpackChunkName: "component---src-pages-prijevodi-jsx" */),
  "component---src-pages-tecajevi-jsx": () => import("./../../../src/pages/tecajevi.jsx" /* webpackChunkName: "component---src-pages-tecajevi-jsx" */)
}

